import { lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ProductFruits } from 'react-product-fruits';
import { LicenseInfo } from '@mui/x-license-pro';
import RootComponents from './services/config/AxiosInterceptorComponent';
import ProtectedRoute from './routes/AuthenticatedRoute';
import PrivateRoute from './routes/PrivateRoute';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import { getProductFruitsData } from './util/productFruits';

// shared
const LoginPage = lazy(() => import('./components/features/shared/LoginPage'));
const RegisterPage = lazy(() => import('./components/features/user/RegisterPage'));
const LinkedInPage = lazy(() => import('./components/features/shared/LinkedInPage'));
const OnBoardingStepsSharedPage = lazy(() => import('./components/features/shared/OnBoardingStepsPage'));
const OnboardingPage = lazy(() => import('./components/features/shared/Onboarding'));
const AccessDeniedPage = lazy(() => import('./components/features/shared/AccessDeniedPage'));

const DoNotKnowPersonDecline = lazy(() => import('./components/features/visitor/DoNotKnowPersonDecline'));
const DoNotWantToHelpDecline = lazy(() => import('./components/features/visitor/DoNotWantToHelpDecline'));

// user
const UserPortalPage = lazy(() => import('./components/features/user/UserPortalPage'));
const GreatChoicePage = lazy(() => import('./components/features/user/GreatChoice'));
const HowWillYouUseKANNYPage = lazy(() => import('./components/features/user/HowWillYouUseKANNY'));

// visitor
const OnBoardingStepsPage = lazy(() => import('./components/features/visitor/OnBoardingStepsPage'));
const VisitorAwShucksPage = lazy(() => import('./components/features/visitor/AwShucks'));
const ReviewUserFromURL = lazy(() => import('./components/features/user/UserPortalPage/ReviewUserFromURL'));

// kanny-admin
const KannyAdminPortalPage = lazy(() => import('./components/features/kanny-admin'));

// member
const MemberPortalPage = lazy(() => import('./components/features/member'));

function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');

  return (
    <Router>
      <ProductFruits user={getProductFruitsData()} workspaceCode={process.env.REACT_APP_PRODUCT_FRUIT || ''} language='en' />

      {<RootComponents />}
      <Routes>
        {/* Shared - Public */}
        <Route index element={<Navigate to='/login' replace={true} />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/login/job/:jobId?' element={<LoginPage />} />

        {/* User registration - Public */}
        <Route path='/user-registration/job?/:jobId?' element={<RegisterPage />} />
        <Route path='/user-registration/:userType/code?/:code?' element={<RegisterPage />} />

        {/* --------- To be removed --------- */}
        <Route path='/user-registration/how-will-you-use/job?/:jobId?' element={<HowWillYouUseKANNYPage />} />
        <Route path='/user-registration/great-choice/:choice/:excludeCurrent/job?/:jobId?' element={<GreatChoicePage />} />
        {/* ------ End To be removed -------- */}

        <Route path='/decline-request-review/do-not-know-person/:requestReviewId' element={<DoNotKnowPersonDecline />} />
        <Route path='/decline-request-review/do-not-want-to-help/:requestReviewId' element={<DoNotWantToHelpDecline />} />

        {/* Return url */}
        <Route path='/linkedin' element={<LinkedInPage />} />

        {/* Access denied route */}
        <Route path='/access-denied' element={<AccessDeniedPage />} />

        {/* Visitor routes */}
        <Route path='/on-boarding/visitor/code?/:code?/:visitorFirstName?/:visitorLastName?' element={<OnBoardingStepsPage />} />
        <Route path='/visitor/aw-shucks' element={<VisitorAwShucksPage />} />

        {/* --------- To be removed --------- */}
        <Route path='/on-boarding-steps/step/:step/:userType/code?/:code' element={<OnBoardingStepsSharedPage />} />
        <Route
          path='/user-on-boarding-steps/step/:step/:userType/:jobSeekingStatus?/:excludeMyCurrentEmployer?/job?/:jobId?'
          element={<OnBoardingStepsSharedPage />}
        />
        {/* ------ End To be removed -------- */}

        <Route path='/onboarding/:step' element={<OnboardingPage />} />

        <Route path='/profile/:vanityName' element={<ReviewUserFromURL />} />

        {/* User portal */}
        <Route
          path='/user-portal/:subpage?'
          element={
            <AuthenticatedRoute>
              <UserPortalPage />
            </AuthenticatedRoute>
          }
        />

        {/* Kanny-admin */}
        <Route
          path='/kanny-admin-portal/:subpage?'
          element={
            <PrivateRoute roles={['KannyAdmin']}>
              <KannyAdminPortalPage />
            </PrivateRoute>
          }
        />

        {/* Member */}
        <Route
          path='/member-portal/:subpage?'
          element={
            <PrivateRoute roles={['Member', 'Admin', 'KannyAdmin']}>
              <MemberPortalPage />
            </PrivateRoute>
          }
        />
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
