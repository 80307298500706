import days from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

const formatDate = (date: Date) => (!!date && !isNaN(new Date(date).getTime()) ? days(new Date(date)).format('YYYY-MM-DD') : '/');
const formatDateWithTime = (date: Date) => (!!date && !isNaN(new Date(date).getTime()) ? days(new Date(date)).format('YYYY-MM-DD') : '/');
const baseUrl = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const url = `${protocol}//${hostname}${port}`;

  return url;
};
const isValidLinkedinVanityUrl = (url: string) => {
  const regex = /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9-]+\/?$/;
  return regex.test(url);
};

const extractVanityName = (linkedinUrl: string) => {
  if (!linkedinUrl) return '';

  const pattern = /\/in\/([^/?#]+)/;
  const match = linkedinUrl.match(pattern);

  if (match && match.length > 1) return match[1];

  return linkedinUrl;
};

const getLinkedinUrlFromVanityName = (vanityName: string) => {
  return `https://www.linkedin.com/in/${vanityName}`;
};

const clearLocalStorage = () => {
  const productFruitsUserId = localStorage.getItem('productFruitsUserId') || uuidv4();

  localStorage.clear();

  localStorage.setItem('productFruitsUserId', productFruitsUserId);
};

const calculateLinearGradientPercentage = (value: number) => Math.abs((100 / value) * 100);

const localDateTimeToString = (date: Date) =>
  date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T00:00:00.0000000Z';
const addCurrentHoursToDate = (date: any) => {
  // Extract current hours, minutes, seconds, and milliseconds
  const currentDate = new Date();

  let currentHours = currentDate.getHours();
  let currentMinutes = currentDate.getMinutes();
  let currentSeconds = currentDate.getSeconds();
  let currentMilliseconds = currentDate.getMilliseconds();

  // Create a new Date object and set its time to the current time
  date.setHours(currentHours, currentMinutes, currentSeconds, currentMilliseconds);

  return date;
};

const getCurrentHourAndMinutes = () => {
  const date = dayjs();
  const formattedTime = date.format('HH:mm'); // 24-hour format with leading zeros for hours and minutes
  return formattedTime;
};

export {
  formatDate,
  formatDateWithTime,
  baseUrl,
  isValidLinkedinVanityUrl,
  extractVanityName,
  getLinkedinUrlFromVanityName,
  clearLocalStorage,
  calculateLinearGradientPercentage,
  localDateTimeToString,
  addCurrentHoursToDate,
  getCurrentHourAndMinutes
};
